import React from "react";
import { Slide } from "react-reveal";
import part from "../../assets/images/content/school-kids.jpg";

const Charity = () => {
  return (
    <section className="section cloudy-bg section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <Slide top>
              <div className="section-head center width-600 margin-auto">
                <h2 className="capitalize">
                  Your business with us educates orphans &amp; keeps girls in
                  school
                </h2>
                <p className=" margin-top-20 width-600 margin-auto">
                  We say <strong>Thank You For Your business With Us</strong>{" "}
                  because your business not only gives our company a life but
                  also educates orphans and keeps girls in school
                </p>
              </div>
            </Slide>
            <div className="section-body section-spacing-50">
              <div className="partners-container flex">
                <div className="partners-text">
                  <div>
                    <p>
                      A percentage of the revenue we get from working on your
                      projects is donated to help educate orphans and support
                      girls in schools in <strong>Educate an Orphan</strong> and{" "}
                      <strong>Educate Her (Girls in School Initiative)</strong>
                    </p>
                    <p className=" margin-top-10">
                      We say thank you for your business on behalf of the lives
                      you support, the lives you touch, the smiles you bring on
                      so many orphans' and girls' faces by giving us business.
                      These are one of the things that inspires us to show up
                      everyday and with you, we move together.
                    </p>
                  </div>
                </div>
                <div className="partners-image green-bg">
                  <img
                    src={part}
                    className="box-shadow"
                    alt="School Girls Smiling"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Charity;
