import React from "react";

const ServiceCard = (props) => {
  return (
    <li className="service-card">
      <img src={props.img} className="service-icon" alt={props.service} />
      <h3 className="service-title uppercase">{props.service}</h3>
      <div className="services-details">{props.children}</div>
      <a href={props.ctaLink} className="button card-cta bold">
        {props.ctaText}
      </a>
    </li>
  );
};

export default ServiceCard;
