import React from "react";
import HomeHero from "../components/home/HomeHero";
import HowItWorks from "../components/home/HowItWorks";
import Served from "../components/home/Served";
import Testimonials from "../components/home/Testimonials";

export default function Home() {
  return (
    <>
      <HomeHero />
      <Served />
      <Testimonials />
      <HowItWorks />
    </>
  );
}
