import React from "react";
import ServedCard from "./ServedCard";

const ServedList = () => {
  return (
    <>
      <ServedCard
        name="CHARITY WATER"
        desc="An International NGO aiming to bring clean water to every person on earth"
        link="https://CharityWater.org"
      />
      <ServedCard
        name="JMSTONE LOGISTICS"
        desc="East Africa’s oil sector leading supplier &amp; installer of geosynthetics, hdpes and institutional uniforms "
        link="https://jmstonelogistics.com"
      />
      <ServedCard
        name="MALIDAD.COM"
        desc="Global Content Publishing Platform and 3 Times Author of 3 best-sellers, Dad Daughter and Dad Son"
        link="https://malidad.com"
      />
      <ServedCard
        name="BRAND CARE LIMITED"
        desc="East Africa's leading printing and branding company. Your partner in quality branding and printing"
        link="https://brandcare.org"
      />
      <ServedCard
        name="NMAU.ORG"
        desc="The National Midwives Association of Uganda for all midwives in Uganda"
        link="https://nmau.org"
      />
      <ServedCard
        name="TWIN TOWER HOTEL"
        desc="Twin Tower Hotel is a serene and affordable environment for a leisure treat in Kampala, Uganda."
        link="https://TwinTowerHotel.com"
      />
      <ServedCard
        name="KAMPALA CITY GRAMMAR SCHOOL"
        desc="KCG is one of the best pre-school and primary schools in Uganda"
        link="https://KampalaCityGrammarSchool.com"
      />
      <ServedCard
        name="DAD DAUGHTER BOOK"
        desc="An International best-seller book that has 200 life lessons every father must teach his daughter"
        link="https://DadDaughterBook.com"
      />
      <ServedCard
        name="GREAT HABIB K9 ACADEMY"
        desc="East Africa's leading dogs and cats training academy"
        link="https://GHK9Academy.com"
      />
      <ServedCard
        name="MyFloat Pay"
        desc="A Ugandan Startup empowering SMEs and Individuals to cheaply start Mobile Money businesses."
        link="https://earn.myfloatpay.com"
      />
      <ServedCard
        name="Corporate Muslims Community"
        desc="A member owned multi-purpose cooperative where members unite to contribute to invest in social-economic development projects."
        link="https://cmcug.org"
      />
      <ServedCard
        name="BURROW.COM"
        desc="A New York based luxury furniture and wood works company."
        link="https://burrow.com"
      />
      <ServedCard
        name="THE LIFETIME TREASURE"
        desc="An International best-seller book that teaches the 18 skills that pay-off forever"
        link="https://TheLifetimeTreasure.com"
      />
      <ServedCard
        name="GREAT HABIB SMART FARM ACADEMY"
        desc="The Farm digitally driving agricultural transformation in Uganda and East Africa"
        link="https://GreatHabibSmartFarmAcademy.com"
      />
      <ServedCard
        name="MARARA SOCIAL AND HEALTH INITIATIVES"
        desc="An non-profit and charitable NGO delivering social interventions."
        link="https://msahi.org"
      />
      <ServedCard
        name="MALIDAD BOOKS"
        desc="The books writing and publishing wing of MALIDAD.COM."
        link="https://books.malidad.com"
      />
    </>
  );
};

export default ServedList;
