import React from "react";
import customer from "../../assets/images/icons/happy-customer-icon.svg";

const ServedCard = (props) => {
  return (
    <li className="served-card">
      <img src={customer} alt="Happy Customer Icon" />
      <h3 className="served-name uppercase">{props.name}</h3>
      <p className="served-desc">{props.desc}</p>
      <a
        href={props.link}
        target="_blank"
        className="served-cta"
        rel="noopener noreferrer"
      >
        See Their Website
      </a>
    </li>
  );
};

export default ServedCard;
