import React from "react";

const CommsBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="overlap minus-margin-top-150">
              <div className="section-content section-spacing-60 no-padding-top">
                <div className="comms-container width-600 margin-auto white-bg padding-4 border-radius-2">
                  <div className="comms-card box-shadow">
                    <div className="comms-head center section-spacing-20">
                      <h3 className="uppercase">
                        Change in our EAST AFRICA Office Business Operations
                      </h3>
                    </div>
                    <div className="comms-body">
                      <p>
                        Dear our esteemed clients and future prospects, with
                        effect from today <strong>1st September, 2023</strong>,
                        we intend to inform you about the changes we have
                        decided upon making with regards to our office business
                        operations for East Africa Business Region.
                      </p>
                      <p>
                        We have trimmed our Ugandan Office Team Occupancy to
                        remaining with only two members, our Business
                        Development Manager in Uganda alongside the Office
                        Secretary, and moved the other team mates to Kenya.
                      </p>
                      <p>
                        Having served many of our clients without physical
                        office engagement, we have decided to make this move to
                        not only minimize our expenses but also be able to serve
                        our clients with a greater efficiency 24/7.
                      </p>
                      <p>
                        This move means, our physical business meetings and
                        engagements for our eseetemed East African clients will
                        happen at our Kenyan office or we shall send our
                        representatives at the client's offices or engage via
                        Zoom or Google Meet.
                      </p>
                      <p className="center">Thank you.</p>
                    </div>
                    <div className="comms-details">
                      <p className="center bold">
                        <span className="block">
                          Administration & Management Department
                        </span>{" "}
                        <span className="block">
                          NTUNA MARKETING COMPANY LIMITED
                        </span>
                        <span className="block">1st September, 2023</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommsBody;
