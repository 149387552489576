import React from "react";
import ServiceCard from "../reusables/ServiceCard";
import web from "../../assets/images/icons2/full-stack-developer-icon.svg";
import market from "../../assets/images/icons2/internet-marketing-icon.svg";
import name from "../../assets/images/icons2/name-icon.svg";
import content from "../../assets/images/icons2/social-media-content-creator-icon.svg";
import strategy from "../../assets/images/icons2/puzzle-icon.svg";
import meeting from "../../assets/images/icons2/meeting-table-icon.svg";

const ServicesBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-body margin-auto padding-16">
              <ul className="services-container flex">
                <ServiceCard
                  img={web}
                  service="Website, App &amp; System Design &amp; Development"
                  ctaText="Get Your Free Quotation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    We design, and develop websites, apps, and systems for
                    your Company, School, NGO, Blog, Hotel,
                    Startup, Ecommerce, Association,
                    Cooperative, etc
                  </p>
                </ServiceCard>
                <ServiceCard
                  img={market}
                  service="Digital Marketing &amp; Social Media Management"
                  ctaText="Get Your Free Quotation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    We manage your social media presence and help you setup ads
                    on Facebook, Twitter, Instagram, Google.
                  </p>
                </ServiceCard>
                <ServiceCard
                  img={name}
                  service="Names &amp; Slogans"
                  ctaText="Get Your Free Quotation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    We compose product &amp; business names, and sentences about
                    you or your business that resonate.
                  </p>
                </ServiceCard>
                <ServiceCard
                  img={content}
                  service="Content Creation &amp; Copywriting"
                  ctaText="Get Your Free Quotation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    We clarify your message so your audience understands it
                    better &amp; take action i.e Content for Web pages, Emails,
                    Sales Letters, Radio and TV Adverts, Announcements etc.
                  </p>
                </ServiceCard>
                <ServiceCard
                  img={strategy}
                  service="Business Strategy &amp; Positioning"
                  ctaText="Get Your Free Quotation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    We help you differentiate your business with better market
                    strategies and tactics based on experience and research and
                    position it against your competitors.
                  </p>
                </ServiceCard>
                <ServiceCard
                  img={meeting}
                  service="Free Business Consultation"
                  ctaText="Claim Your Free Consultation"
                  ctaLink="https://form.jotform.com/210374256613047"
                >
                  <p>
                    This free business consultation is currently available to
                    brands that have at least an annual revenue of USD 15,000 or
                    50 Million UGX or the equivalent in your country currency.
                    We help you know your business better.
                  </p>
                </ServiceCard>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBody;
