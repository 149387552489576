import React from "react";
const Faq = (props) => {
  return (
    <li className="faq box-shadow">
      <details className="details" open>
        <summary className="summary dark">{props.faq}</summary>
        <div className="answer-container margin-top-10 font-size-16">
          {props.children}
        </div>
      </details>
    </li>
  );
};

export default Faq;
