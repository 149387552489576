import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound404 from "./pages/PageNotFound404";
import Map from "./pages/Map";
import Faq from "./pages/Faq";
import Services from "./pages/Services";
import Offer from "./pages/Offer";
import About from "./pages/About";
import Served from "./pages/Served";
import HowWeWork from "./pages/HowWeWork";
import Communications from "./pages/Communications";
//import Renewals from "./pages/Renewals";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/home">
                <Home />
              </Route>
              {/*
              <Route exact path="/renewals">
                <Renewals />
              </Route>
              */}
              <Route exact path="/about-us">
                <About />
              </Route>
              <Route exact path="/served-clients">
                <Served />
              </Route>
              <Route exact path="/contact-us">
                <Map />
              </Route>
              <Route exact path="/services">
                <Services />
              </Route>
              <Route exact path="/webdesign-offer">
                <Offer />
              </Route>
              <Route exact path="/how-we-work">
                <HowWeWork />
              </Route>
              <Route exact path="/communications-and-updates">
                <Communications />
              </Route>
              <Route exact path="/faqs">
                <Faq />
              </Route>

              <Route exact path="/404">
                <PageNotFound404 />
              </Route>

              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    );
  }
}
