import React from "react";
import map from "../../assets/images/icons/map.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import fb from "../../assets/images/icons/facebook.svg";
import twi from "../../assets/images/icons/twitter.svg";
import wa from "../../assets/images/icons/whatsapp.svg";

const Footer = () => {
  return (
    <section className="footer dark-bg section-spacing-20 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-50">
              <ul className="footer-navigation flex">
                <li className="footer-nav-item footer-about">
                  <h3 className="white flex">NTUNA.COM</h3>
                  <div className="footer-about-container light-gray">
                    <p>
                      NTUNA.COM is a sortlist verified and badged best in class
                      International Software Development &amp; Digital Marketing
                      Company.
                    </p>
                    <p>
                      We are responsible for over 500 websites, apps, systems
                      and digital marketing projects around the globe since our
                      inception in 2017.
                    </p>
                    <ul className="footer-socials">
                      <li className="wa-bg button">
                        <a
                          href="https://wa.me/13159794240"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={wa} alt="NTUNA.COM on WhatsApp" />
                        </a>
                      </li>
                      <li className="twi-bg button">
                        <a
                          href="https://twitter.com/NTUNA_COM"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={twi} alt="NTUNA.COM on Twitter" />
                        </a>
                      </li>
                      <li className="fb-bg button">
                        <a
                          href="https://facebook.com/NTUNAcom"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={fb} alt="NTUNA.COM on Facebook" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="footer-nav-item">
                  <h3 className="white uppercase center">Quick Links</h3>
                  <ul className="footer-menu center">
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                    <li>
                      <a href="/services">Our Services</a>
                    </li>
                    <li>
                      <a href="/how-we-work">How We Work</a>
                    </li>
                    <li>
                      <a href="/served-clients">Our Served Clients</a>
                    </li>
                    <li>
                      <a href="/webdesign-offer">Web Design Offer For You</a>
                    </li>
                    <li>
                      <a href="/faqs">Frequently Asked Questions</a>
                    </li>
                  </ul>
                </li>
                <li className="footer-nav-item footer-support">
                  <h3 className="white uppercase">Support &amp; Contacts</h3>
                  <div className="support-items light-gray">
                    <div className="support-item">
                      <img src={map} alt="Map Icon" />
                      <p>
                        <span>|--| Times Square, 42 st, New York, USA</span>{" "}
                        <span>
                          |--| BR Blocks, Buganda Road, Kampala, Uganda
                        </span>{" "}
                        <span>
                          |--| 500 Jorrissen Street, Pretoria, South Africa
                        </span>
                        <span>|--| Denis Prit Road, Nairobi, Kenya</span>
                      </p>
                    </div>
                    <div className="support-item">
                      <img src={phone} alt="Call Icon" />
                      <p>
                        <span>+1 (315) 979 4240</span>{" "}
                        <span>+256 (702) 096 666</span>{" "}
                      </p>
                    </div>
                    <div className="support-item">
                      <img src={email} alt="Email Icon" />
                      <p>
                        <span>support@ntuna.com</span>{" "}
                        <span>ntuna.web@gmail.com</span>{" "}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="copyright padding-top-20 padding-bottom-8 center ntuna-yellow">
              <p>
                &copy; 2017-2024 NTUNA MARKETING COMPANY LIMITED, All Rights
                Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
