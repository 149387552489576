import React from "react";

const OfferCard = (props) => {
  return (
    <li className="offer-card">
      <img src={props.img} className="offer-icon" alt={props.offer} />
      <h3 className="offer-title uppercase">{props.offer}</h3>
      <div className="offer-details">{props.children}</div>
    </li>
  );
};

export default OfferCard;
