import React from "react";
import { Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings2 } from "../reusables/testCarousel";
import TestimonialCard from "../reusables/TestimonialCard";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const Testimonials = () => {
  return (
    <section className="sections cloudy-bg section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h2 className="capitalize">Testimonials &amp; Badges</h2>
                  <p className=" margin-top-20 width-600 margin-auto">
                    What our served clients have to tell you about us.
                  </p>
                </div>
              </Slide>
              <div className="section-body">
                <div className="sortlist width-800 margin-auto">
                  <div>
                    <a
                      href="https://www.sortlist.com/agency/ntuna-tech"
                      className="sortlist"
                    >
                      <img
                        src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-blue-light-xs.svg"
                        alt="flag"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.sortlist.com/agency/ntuna-tech"
                      className="sortlist"
                    >
                      <img
                        src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-stamp-blue-light-xs.svg"
                        alt="stamp"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.sortlist.com/agency/ntuna-tech"
                      className="sortlist"
                    >
                      <img
                        src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-stars-blue-light-xl.svg"
                        alt="stars"
                      />
                    </a>
                  </div>
                </div>
                <div className="testimonials-container slider-items-container">
                  <Slider {...settings2}>
                    <TestimonialCard
                      test="NTUNA.COM not only designed and built our website but also helped us achieve over 300% growth on our startup through digital marketing in less than 2 weeks."
                      testCompany="Co-Founder, MyFloat Pay"
                      testUser="Sayuni Edgar"
                    />
                    <TestimonialCard
                      test="NTUNA.COM helped us build MALIDAD.COM brand from the word go. They listened to our needs and gave us more than we wanted."
                      testCompany="Operations Manager, MALIDAD.COM"
                      testUser="Aisha Namugenyi"
                    />
                    <TestimonialCard
                      test="We got NTUNA.COM as a referal from a close friend. We made it a point to let them build our website and handle our social media pages. They are so listening."
                      testCompany="Manager, TWIN TOWER HOTEL"
                      testUser="B. JAFFAR"
                    />
                    <TestimonialCard
                      test="We wanted our website done in less than a week because of the ahead event we had. NTUNA.COM got the job perfectly done in 3 days."
                      testCompany="Head Teacher, Kampala City Grammar School"
                      testUser="Ssenabulya Francis"
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
