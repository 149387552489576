import React from "react";

const AboutBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="overlap minus-margin-top-150">
              <div className="section-content section-spacing-60 no-padding-top">
                <div className="module">
                  <div className="module-inner">
                    <div className="width-600 margin-auto box-shadow white-bg padding-4 border-radius-2">
                      <div className="section-head">
                        <div className="title-container">
                          <div className="module">
                            <div className="module-inner no-margin-bottom">
                              <h3 className="title center font-size-2-5 navy">
                                NTUNA.COM
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="subtitle-container">
                          <div className="module">
                            <p className="rich-text center">
                              INT'L SOFTWARE DEVELOPMENT &amp; MARKETING COMPANY
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="section-body about-body justify">
                        <p>
                          NTUNA.COM was established in 2017. We started as a
                          couple of fresh out-of-the-university programmers and
                          managed to build a team that is now one of the most
                          reputable software development agencies in the world.
                          That as of today, we have set foot in every continent
                          not just by our works but also official presence.
                        </p>
                        <p>
                          We have completed hundreds of projects in websites,
                          apps and systems since 2017. Big and Small, Easy and
                          Complex, Paid and pro bono. We are never afraid to
                          fail if that will lead us to learn something new and
                          expand our professional and personal horizons.
                        </p>
                        <p>
                          We're a team of professionals and we do our best to
                          satisfy our clients' needs and expectations. Our team
                          consists of software engineers and designers with 10+
                          years of active experience.
                        </p>
                        <p>
                          NTUNA.COM was established in 2017 with a purpose to
                          help brands not just set their feet in the world of
                          tech but also win there.
                        </p>
                        <p>
                          If you Need a Website, App, System or Digital
                          Marketing? For your Company, School, NGO, Blog, Hotel,
                          Startup, Ecommerce, Tour &amp; Travel, Farm, Personal
                          Brand, Construction Company, Association, Cooperative,
                          etc., we are at your service.
                        </p>
                        <p>
                          Just Call/WhatsApp:{" "}
                          <a
                            href="https://wa.me/13159794240"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="blue underline"
                          >
                            +1 (315) 979 4240
                          </a>{" "}
                          or{" "}
                          <a
                            className="blue underline"
                            href="https://wa.me/256702096666"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +256 (702) 096 666
                          </a>{" "}
                          or{" "}
                          <a
                            href="https://form.jotform.com/210374256613047"
                            className="blue"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Get Your Free Quotation Here
                          </a>
                        </p>
                        <p className="margin-top-16 center bold navy">
                          Thank you for coming cover. <br></br>
                          We Love You :)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
