import React from "react";
import ServedCard from "../reusables/ServedCard";
import ServedList from "../reusables/ServedList";

const ServedBody = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-body section-spacing-60 width-960 margin-auto">
              <div className="center">
                <p>Here are some of the clients we have served.</p>
              </div>
              <ul className="served-container flex">
                <ServedList />
              </ul>
              <div className="section-cta section-spacing-50 no-padding-bottom">
                <a
                  href="https://form.jotform.com/210374256613047"
                  className="button uppercase bold"
                >
                  {" "}
                  Get a Free Quotation For Your Website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServedBody;
