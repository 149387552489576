import React from "react";
import { Slide } from "react-reveal";
import scroll from "../../assets/images/icons/double-arrow-bottom-icon.svg";

const HomeHero = () => {
  return (
    <section className="hero light-blue-code-bg section-spacing-150 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="hero-content-container">
                <div className="hero-text-side">
                  <div className="hero-text">
                    <span className="ug">
                      international software development &amp; Digital Marketing
                      Company
                    </span>
                    <Slide top>
                      <h1 className="white">
                        Need a Website, App, System or Digital Marketing?
                      </h1>
                    </Slide>
                    <Slide left>
                      <p>
                        For your Company, School, NGO, Blog, Hotel, Startup,
                        Ecommerce, Tour &amp; Travel, Farm, Personal Brand,
                        Construction Company, Association, Cooperative, etc.
                      </p>
                    </Slide>
                  </div>
                  <div className="hero-cta">
                    <a
                      href="https://form.jotform.com/210374256613047"
                      className="main-cta button uppercase bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Your Free Quotation
                    </a>
                    <span className="block margin-top-10 hero-500">
                      <span>
                        <a
                          href="/served-clients"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          500+ Projects {" "}
                        </a>
                        Satisfactorily Done Since 2017.
                      </span>
                    </span>
                    <span className="block margin-top-10 white">
                      <span>
                        <a
                          className="white no-underline"
                          href="tel:+256702096666"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Call
                        </a>
                      </span>
                      /
                      <span>
                        <a
                          href="https://wa.me/13159794240"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="white no-underline"
                        >
                          WhatsApp
                        </a>
                        :{" "}
                      </span>
                      <span>
                        <a
                          href="https://wa.me/13159794240"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="white underline"
                        >
                          +1 (315) 979 4240
                        </a>
                      </span>{" "}
                      or{" "}
                      <span>
                        <a
                          className="white underline"
                          href="https://wa.me/256702096666"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          +256 (702) 096 666
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="scroll-container">
                <img src={scroll} alt="Double Bottom Arrows" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
