import React from "react";
import customer from "../../assets/images/icons/feedback-testimonial-icon.svg";

const TestimonialCard = (props) => {
  return (
    <div className="testim-card center">
      <img src={customer} alt="Satisfied Customer Icon" />
      <div className="testim-text">
        <p>“{props.test}”</p>
      </div>
      <div className="testim-user">
        <h4 className=" uppercase">{props.testUser}</h4>
        <p>{props.testCompany}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
