import React from "react";
import { Helmet } from "react-helmet";
import CommsBody from "../components/communications/CommsBody";
import PageHero from "../components/reusables/hero/PageHero";

export default function Communications() {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Need a Website, App, System or Digital Marketing Services? Ntuna.com is at your service. Call/WhatsApp +1 (315) 979 4240 or +256 (702) 096 666"
        />
        <meta
          property="og:description"
          content="Need a Website, App, System or Digital Marketing Services? Ntuna.com is at your service. Call/WhatsApp +1 (315) 979 4240 or +256 (702) 096 666"
        />
        <meta
          property="og:title"
          content="NTUNA.COM - Communications &amp; Updates"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707718049/NTUNA-banner-3_vyqrfe.jpg"
        />

        <meta
          name="twitter:title"
          content="NTUNA.COM - Communications &amp; Updates"
        />
        <meta
          name="twitter:text:title"
          content="NTUNA.COM - Communications &amp; Updates"
        />
        <meta
          name="twitter:description"
          content="Need a Website, App, System or Digital Marketing Services? Ntuna.com is at your service. Call/WhatsApp +1 (315) 979 4240 or +256 (702) 096 666"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707718049/NTUNA-banner-3_vyqrfe.jpg"
        />
        <title>NTUNA.COM - Communications &amp; Updates</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title uppercase ntuna-yellow"
        title="Communications &amp; Updates"
      />
      <CommsBody />
    </>
  );
}
