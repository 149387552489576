import React from "react";
import { Slide } from "react-reveal";
import ui1 from "../../assets/images/content/web2r.png";

const HowBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <p className="margin-top-20">
                    Below is a three step outline of how we do our work to make
                    you win on the web, meet your highest satisfaction and give
                    you the best value for your dollar.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="how-container">
                  <div className="sideA how-text-side">
                    <ul>
                      <Slide left>
                        <li className="how-step-card">
                          <div className="how-number">1</div>
                          <h4 className=" capitalize">
                            Provide Project Details
                          </h4>
                          <p>
                            Let us know what exactly you want for your website
                            or your digital marketing. This comes with a free
                            quotation after providing the details.
                          </p>
                        </li>
                      </Slide>
                      <Slide bottom>
                        <li className="how-step-card">
                          <div className="how-number">2</div>
                          <h4 className=" capitalize">
                            Agree on charge &amp; deposit 50%
                          </h4>
                          <p>
                            Once we agree on costs for your project, you will
                            make an initial deposit of 50% the agreed charge for
                            us to start work on your project.
                          </p>
                        </li>
                      </Slide>
                    </ul>
                  </div>
                  <Slide top>
                    <div className="uiImage ntuna-yellow-bg padding-16 box-shadow">
                      <img src={ui1} alt="Responsive Website Design" />
                    </div>
                  </Slide>
                  <div className="sideB how-text-side">
                    <ul>
                      <Slide right>
                        <li className="how-step-card">
                          <div className="how-number">3</div>
                          <h4 className=" capitalize">
                            Feedback, Tweaks &amp; Twists
                          </h4>
                          <p>
                            As we work on your project, we keep sharing with you
                            our progressive output so we can get your feedback
                            and incorporate it accordingly.
                          </p>
                        </li>
                      </Slide>
                      <Slide top>
                        <li className="how-step-card">
                          <div className="how-number">4</div>
                          <h4 className=" capitalize">Final Work Delivery</h4>
                          <p>
                            Once you approve the final work on your project, you
                            make the 50% balance deposit and we handover your
                            project with it's report.
                          </p>
                        </li>
                      </Slide>
                    </ul>
                  </div>
                </div>
                <div className="section-cta section-spacing-50 no-padding-bottom">
                  <a
                    href="https://form.jotform.com/210374256613047"
                    className="button uppercase bold"
                  >
                    {" "}
                    Get Your Free Quotation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowBody;
