import React from "react";
import map from "../../assets/images/icons/map.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import fb from "../../assets/images/icons/facebook.svg";
import twi from "../../assets/images/icons/twitter.svg";
import wa from "../../assets/images/icons/whatsapp.svg";
import { Slide } from "react-reveal";

const DirectionBody = () => {
  return (
    <section className="address section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-960 margin-auto">
              <div className="address-wrap flex">
                <Slide left>
                  <div className="map">
                    <h3>Address</h3>
                    <div className="map-container width-100">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d127671.99241542735!2d32.486232524844446!3d0.33588355264053216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x177dbbbcaaa97d0b%3A0xbbc111bb90217094!2sMakerere%20University%2C%20Pool%20road%2C%20COCIS%2C%20Kampala!3m2!1d0.33588389999999996!2d32.5686345!5e0!3m2!1sen!2sug!4v1693922327256!5m2!1sen!2sug"
                        style={{ border: 0 }}
                        allowfullscreen="true"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </Slide>
                <Slide right>
                  <div className="address-office">
                    <div>
                      <h3>Office &amp; Contacts</h3>
                      <div className="contacts-container">
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={map} alt="Map Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Location</h4>
                            <p className="margin-top-10">
                              <span>
                                |--| Times Square, 42 st, New York, USA
                              </span>{" "}
                              <span>
                                |--| BR Blocks, Buganda Road, Kampala, Uganda
                              </span>{" "}
                              <span>
                                |--| 500 Jorrissen Street, Pretoria, South
                                Africa
                              </span>
                              <span>|--| Denis Prit Road, Nairobi, Kenya</span>
                            </p>
                          </div>
                        </div>
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={phone} alt="Call Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Phone</h4>
                            <p className=" margin-top-10">+1 (315) 979 4240</p>
                            <p className=" margin-top-10">+256 702 096 666</p>
                          </div>
                        </div>
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={email} alt="Email Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Email &amp; Post Office</h4>
                            <p className=" margin-top-10">
                              ntuna.web@gmail.com | support@ntuna.com
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
              </div>
              <div className="address-message flex section-spacing-30">
                <a
                  href="https://form.jotform.com/210375073550550"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                >
                  Get in Touch
                </a>

                <ul className="social-icons flex">
                  <li className="wa-bg button">
                    <a
                      href="https://wa.me/13159794240"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={wa} alt="NTUNA.COM on WhatsApp" />
                    </a>
                  </li>
                  <li className="twi-bg button">
                    <a
                      href="https://twitter.com/NTUNA_COM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twi} alt="NTUNA.COM on Twitter" />
                    </a>
                  </li>
                  <li className="fb-bg button">
                    <a
                      href="https://facebook.com/NTUNAcom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} alt="NTUNA.COM on Facebook" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectionBody;
