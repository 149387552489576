import React, { useState } from "react";
import bars from "../../assets/images/icons/bars.svg";
import close from "../../assets/images/icons/close.svg";
import whatsAppIcon from "../../assets/images/icons/whatsapp.png";

const Header = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <header className="site-header">
      <div className="container">
        <div className="large">
          <div className="header-contents">
            <div className="site-brand">
              <a href="/" className="brand-logo block">
                <h1>NTUNA.COM</h1>
              </a>
            </div>
            <nav className="site-nav">
              <label
                onClick={() => setShowParent(true)}
                htmlFor="toggle-responsive-menu"
                className="nav-label bold-title"
              >
                <img src={bars} alt="Menu" />
              </label>
              <input type="checkbox" id="toggle-responsive-menu" />
              <div
                onClose={() => setShowParent(false)}
                showParent={showParent}
                className={`nav-inner ${showParent ? "showParent" : ""}`}
              >
                <div className="hide-menu">
                  <button
                    onClick={() => setShowParent(false)}
                    className="hide-menu-btn button"
                  >
                    <img src={close} alt="Close Menu" />
                  </button>
                </div>

                <ul className="site-menu" onClick={() => setShowParent(false)}>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li>
                    <a href="/services">Our Services</a>
                  </li>
                  <li>
                    <a href="/how-we-work">How We Work</a>
                  </li>
                  <li>
                    <a href="/webdesign-offer">Web Design Offer</a>
                  </li>
                  <li>
                    <a href="/served-clients">Our Served Clients</a>
                  </li>
                  <li>
                    <a href="/faqs">Frequently Asked Questions</a>
                  </li>
                  <li className="whatsapp-button button">
                    <a
                      href="https://wa.me/256702096666"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="WhatsApp Us"
                    >
                      <img
                        src={whatsAppIcon}
                        class="icon"
                        alt="WhatsApp Icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
