const settings2 = {
  dots: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 1,
  swipeToSlide: true,
  rows: 1,
  autoplay: true,
  speed: 5000,
  pauseOnHover: true,
  className: "slider-items-container",
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1039,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 799,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 727,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export { settings2 };
